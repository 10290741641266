<template>
  <v-main>
    <v-form ref="loginForm"
            v-model="valid"
            lazy-validation>
      <v-container class="mt-16" >

        <v-row justify="center">
          <v-col sm="6"
                 lg="4"
                 xl="4">
            <v-text-field outlined
                          dense
                          ref="usernameField"
                          v-model="loginUsername"
                          :rules="requiredRules"
                          label="Nutzername"
                          required></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col sm="6"
                 lg="4"
                 xl="4">
            <v-text-field outlined
                          dense
                          ref="passwordField"
                          v-model="loginPassword"
                          :rules="requiredRules"
                          :type="'password'"
                          name="input-10-1"
                          label="Password"
                          @keyup.enter="validate"></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="d-flex"
                 sm="6"
                 lg="4"
                 xl="4"
                 align-end>
            <v-btn color="secondary"
                   large
                   block
                   :disabled="!valid"
                   @click="validate">Login
            </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
  </v-main>
</template>

<script>
export default {
  name: "LoginComponent",
  data: () => ({
    valid: false,
    loginUsername: "",
    requiredRules: [
      v => !!v || "Required",
    ],
    loginPassword: "",

  }),
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        const newUrl = this.$route.query.forward || "/"
        this.$store.dispatch("login", {
          username: this.loginUsername,
          password: this.loginPassword
        }).then(() => this.$router.push(newUrl)).catch(function () {
        })
      }
    },
  },
  mounted() {
    this.$refs["usernameField"].focus();
  }
}
</script>

<style scoped>

</style>