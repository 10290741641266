<template>
    <v-main>
        <v-container fluid fill-height>
            <v-row
                    v-for="(button, idx) in buttons"
                    :key="idx"
                    :align="'center'">
                <v-col
                        cols="3"
                        sm="2"
                        :align="'center'">
                    <a :href="button.link">
                        <v-img
                                alt="Logo"
                                class="shrink mr-2"
                                contain
                                :src="require('../assets/' + button.icon)"
                                width="100"
                        />
                    </a>
                </v-col>
                <v-col :justify="'start'">
                    <v-btn
                            :height="100"
                            x-large
                            block
                            :color="button.color"
                            @click="startFlow(button)">
                        {{ button.text }}
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'HomeComponent',
    data() {
        return {
            buttons: [
                {
                    text: "Ausbuchen",
                    flow: "ausbuchen",
                    icon: "minus.svg",
                    color: "primary"
                },
                {
                    text: "Einbuchen",
                    flow: "einbuchenalt",
                    icon: "add.svg",
                    color: "primary"
                },
                {
                    text: "Preis Ändern",
                    flow: "preis",
                    icon: "tag.png",
                    color: "secondary",
                    link: "/label"
                },
            ],
            ordersCount: null
        }
    },
    methods: {
        startFlow(newFlow) {
            this.$store.commit("setActualFlow", newFlow)
            this.$router.push(newFlow.flow)
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
