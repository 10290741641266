<template>
    <v-card>
        <v-card-title>{{ product.data.title }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="flex-grow-1" :align="'start'">
              <v-col cols="8">
                <div v-if="product.data.price">Preis: {{ product.data.price}}</div>
                <div v-if="product.data.sku">SKU: {{ product.data.sku}}</div>
                <div v-if="product.data.size">Größe: {{ product.data.size}}</div>
                <div v-if="product.data.color">Farbe: {{ product.data.color}}</div>
              </v-col>
              <v-col>
                <v-img
                    v-if="defaultImage"
                    height="100"
                    contain
                    :src="defaultImage.thumbUrl"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "ProductDetails",
  data() {
    return {

    }
  },
  computed: {
    product: {
      get() {
        return this.$store.state.product
      }
    },
    defaultImage: {
      get() {
        const images = this.product.data.images;
        return images.find(i => i.default)
      }
    }
  },
  mounted() {
    if (process.env.VUE_APP_DEBUG_MODE) console.log(this.product)
    // if (this.$store.state.product === undefined)
    // {
    //   // if no product is read (for example because of a reload) redirect to scan page
    //   this.$router.push("/scan")
    // }
  }
}
</script>

<style scoped>

</style>