import { render, staticRenderFns } from "./ItemPick.vue?vue&type=template&id=23189c34&scoped=true"
import script from "./ItemPick.vue?vue&type=script&lang=js"
export * from "./ItemPick.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23189c34",
  null
  
)

export default component.exports