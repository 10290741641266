<script setup>

import {onMounted, ref} from "vue"
import {computed} from "vue";
import {useStore} from "@/store/store";
import ProductQuantityChanger from "@/components/ProductQuantityChanger.vue";
import {useRouter} from "@/routes";
import validation from "@/util/validation"
import util from "@/util/util";
import StorageLocationsTable from "@/components/StorageLocationsTable.vue";


let store = useStore();
let router = useRouter()

let storageLocations = computed(() => {
  return store.state.product.storageLocations

});

const valid = ref(false)
const storageLocation = ref("")
// the ref is matched by the variable name
const storageField = ref(null)
const quantityChange = ref(1)

// hooks
onMounted(() => {
  storageField.value?.focus();

  if (!store.state.product.productId) {
    const currentEan = router.currentRoute.params['ean']
    store.dispatch("loadProductV2", currentEan)
  }
})

function handleResponse() {
  store.dispatch('showInfo', {
    text: "OK." + util.formatStorageMessage(store.state.product.ean, storageLocation.value, quantityChange.value),
    time: 3000
  })
  reset()
  router.push("/einbuchen")
}

function storageLocationEntered() {
  console.log(storageLocation.value)
  let regExpExecArray = validation.storagePattern.exec(storageLocation.value);
  const coordinates = {
    corridor: regExpExecArray[1],
    shelf: regExpExecArray[2],
    box: regExpExecArray[3]
  }

  let payload = {storageCoordinates: coordinates, modifyBillbee: true};
  if (store.state.actualProductQuantity > 1) {
    store.commit("openQuantityWarningDialog", {
      text: store.state.actualProductQuantity,
      callback: () => {
        store.dispatch("enterStorageLocation", payload).then(handleResponse)
      },
      cancelCallback: () => {
      }
    })
  } else {
    store.dispatch("enterStorageLocation", payload).then(handleResponse)
  }
}

function noop() {
  // surprisingly does nothing
}

function reset() {
  store.state.actualProductQuantity = 1
  storageLocation.value = ""
  store.commit('setProduct', {})
  store.commit("setActualProductQuantity", 1)
}

onMounted(() => {

  const tempStorage = {
    corridor: 99, shelf: 1, box: 1
  }
  for (const i in storageLocations.value) {
    const storageLocation = storageLocations.value[i]
    if (util.isEqualLocation(tempStorage, storageLocation)) {
      store.dispatch("showInfoConfirm", {
        text: "ACHTUNG: Artikel ist in Lager 99!",
        buttonText: "Ok"
      })
    }
  }
})

</script>

<template>
  <v-main class="mt-5 ml-5 mr-5">
    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="'Einbuchen'"></div>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="6" xl="6">
        <v-card>
          <v-card-text>
            <div v-if="store.state.product.ean">EAN: {{ store.state.product.ean }}</div>
            <div v-if="store.state.product.title">Artikel: {{ store.state.product.title }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col sm="12" md="12" lg="6" xl="6">
        <StorageLocationsTable :items="storageLocations"/>
      </v-col>

    </v-row>
    <v-row class="mt-5">
      <v-col sm="12" md="6" lg="6" xl="6">
        <ProductQuantityChanger :target="quantityChange"/>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-form ref="storageEntry" v-model="valid" v-on:submit.prevent="noop">
        <v-col class="flex-grow-1">
          <v-text-field
              v-model="storageLocation"
              ref="storageField"
              clearable
              dense
              outlined
              :label="'Lagerplatz scannen'"
              :rules="validation.storageRules"
              @keyup.enter="storageLocationEntered"></v-text-field>
        </v-col>
      </v-form>
      <v-col>
        <v-btn color="secondary" tile :disabled="!valid" @click="storageLocationEntered">OK</v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<style scoped>

</style>