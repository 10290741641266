<script setup >

let tableHeaders = [
  {
    text: "Gang",
    sortable: false,
    value: "corridor"
  },
  {
    text: "Platz",
    sortable: false,
    value: "shelf"
  },
  {
    text: "Box",
    sortable: false,
    value: "box"
  },
  {
    text: "Menge",
    sortable: false,
    value: "quantity"
  }
]

const props = defineProps(["items"])

</script>

<template>
  <v-data-table
      disable-pagination
      :hide-default-footer="true"
      no-data-text="noch nicht im Lager"
      :headers="tableHeaders"
      :items="props.items"
      class="elevation-1"
      :mobile-breakpoint="0"
  >
    <template v-slot:[`item.corridor`]="{ item }">
      <div>
        <span class="corridor">{{ item.corridor }}</span>
      </div>
    </template>
    <template v-slot:[`item.shelf`]="{ item }">
      <div>
        <span class="shelfBox">{{ item.shelf }}</span>
      </div>
    </template>
    <template v-slot:[`item.box`]="{ item }">
      <div>
        <span class="shelfBox">{{ item.box }}</span>
      </div>
    </template>
    <template v-slot:[`item.quantity`]="{ item }">
      <span class="quantity">{{ item.quantity }}x</span>
    </template>

  </v-data-table>
</template>

<style scoped>
.corridor {
  font-weight: bold;
  font-size: 20pt;
  color: #ffc000;
}
.shelfBox {
  font-weight: bold;
  font-size: 20pt;
  color: #1B2046;
}

.quantity {
  font-weight: bold;
  font-size: 12pt;
  color: darkgrey;
}
</style>