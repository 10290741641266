<script setup>
import {ref} from "vue";
import validation from "@/util/validation"
import backendInterface from "@/util/interface";
import {useRouter} from "@/routes";
import {useStore} from "@/store/store";

let store = useStore()
let router = useRouter()

const eanValid = ref(false)
const locationValid = ref(false)

const ean = ref("")
const storageLocation = ref("")
// the ref is matched by the variable name
const storageField = ref(null)

const eanRules = [
  v => !!v || "Required",
]

const storagePattern = /(\d+)\.(\d+)\.?(\d+)*/
const storageRules = [
  v => !!v || "Required",
  v => storagePattern.test(v) || "Ungültiger Format (Gang.Platz.Box)",
]

const noop = () => {
}

function doProductSearch() {
  store
      .dispatch("loadProductV2", ean.value)
      .then(function (productResponse) {
        if (productResponse.code === backendInterface.codes.ProductFoundInBillbee) {
          // we can proceed to the normal flow
          router.push("/abfrage/produkt/" + ean.value)
        }
      })
      .catch(function () {
        console.log("error")
        router.push('/home');
      })
}

function doStorageLocationSearch() {
  let regExpExecArray = validation.storagePattern.exec(storageLocation.value);
  const coordinates = {
    corridor: regExpExecArray[1],
    shelf: regExpExecArray[2],
    box: regExpExecArray[3]
  }

  store
      .dispatch("queryStorageLocation", coordinates)
      .then(function (productResponse) {
        if (productResponse.code === "Ok") {
          router.push("/abfrage/lagerplatz/" + storageLocation.value)
        }
      })
      .catch(function () {
        console.log("error")
        router.push('/home');
      })
}

</script>

<template>
  <v-main class="mt-5 ml-5">
    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="'Abfrage'"></div>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="7">
        <v-form ref="eanEntry" v-model="eanValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="ean"
              ref="eanField"
              clearable
              dense
              outlined
              inputmode="numeric"
              :label="'EAN'"
              :rules="eanRules"
              @keyup.enter="doProductSearch"></v-text-field>
        </v-form>
      </v-col>

      <v-col>
        <v-btn color="secondary" tile :disabled="!eanValid" @click="doProductSearch">Suchen</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="7">
        <v-form ref="storageEntry" v-model="locationValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="storageLocation"
              ref="storageField"
              clearable
              dense
              outlined
              :label="'Lagerplatz scannen'"
              :rules="storageRules"
              @keyup.enter="doStorageLocationSearch"></v-text-field>
        </v-form>
      </v-col>
      <v-col>
        <v-btn color="secondary" tile :disabled="!locationValid" @click="doStorageLocationSearch">Suchen</v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<style scoped>

</style>