<script setup>
import {useStore} from "@/store/store";
import {computed, onMounted, ref} from "vue";
import validation from "@/util/validation";
import backendInterface from "@/util/interface";
import {useRouter} from "@/routes";
import util from "@/util/util";

const store = useStore()
const router = useRouter()

const eanValid = ref(false)
const locationValid = ref(false)
const locationContainsProduct = ref(false)

const ean = ref("")
// the ref is matched by the variable name
const eanField = ref(null)

const storageLocation = ref("")
// the ref is matched by the variable name
const storageField = ref(null)

// the ref is matched by the variable name

const eanRules = [
  v => !!v || "Required",
]

const storagePattern = /(\d+)\.(\d+)\.?(\d+)*/
const storageRules = [
  v => !!v || "Required",
  v => storagePattern.test(v) || "Ungültiger Format (Gang.Platz.Box)",
]

const storageLocationCoordinates = computed(() => {
  let regExpExecArray = validation.storagePattern.exec(storageLocation.value);
  return {
    corridor: parseInt(regExpExecArray[1]),
    shelf: parseInt(regExpExecArray[2]),
    box: regExpExecArray[3] ? parseInt(regExpExecArray[3]) : null
  }
})

function doStorageLocationSearch() {
  if (locationValid.value) {
    const coordinates = storageLocationCoordinates.value

    let storageLocationContainsProduct = false
    for (const i in store.state.pickingItem.item.orderItem.storageLocations) {
      let currentStorageLocation = store.state.pickingItem.item.orderItem.storageLocations[i];
      if (util.isEqualLocation(coordinates, currentStorageLocation)) {
        storageLocationContainsProduct = true
        locationContainsProduct.value = true
        break
      }
    }

    if (!storageLocationContainsProduct) {
      store.dispatch('showError', {text: "falscher Lagerplatz!", time: 3000})
    } else {
      setTimeout(() => eanField.value?.focus(), 100)
    }
  }
}

function confirmPick() {
  let paddedEan = padEAN(ean.value)
  let pickingItem = store.state.pickingItem;
  let orderItem = pickingItem.item.orderItem;
  if (paddedEan === orderItem.product.ean) {
    const coordinates = storageLocationCoordinates.value
    const request = {
      productId: orderItem.product.internalProductId,
      pickedItemId: pickingItem.item.pickedItemId,
      billbeeOrderId: pickingItem.order.billbeeOrderId,
      billbeeOrderItemId: orderItem.billbeeId,
      sellingPlatform: pickingItem.order.sellingPlatform,
      storageLocation: coordinates,
      quantity: 1
    }

    store
        .dispatch('pickItemV2', request)
        .then(function (itemPickResponse) {
          if (itemPickResponse.code === backendInterface.codes.ItemPicked) {
            store.dispatch('showInfo', {text: "Artikel erfolgreich gepickt." + util.formatStorageMessage(orderItem.product.ean, storageLocation.value, "1"), time: 6000})
          } else if (itemPickResponse.code === backendInterface.codes.ItemPickedAndOrderUpdated) {
            store.dispatch('showInfo', {text: "Artikel erfolgreich gepickt und Bestellung aktualisiert." + util.formatStorageMessage(orderItem.product.ean, storageLocation.value, "1"), time: 3000})
          } else if (itemPickResponse.code === backendInterface.codes.ItemAlreadyPicked) {
            store.dispatch('showError', {text: "Artikel bereits gepickt", time: 6000})
          }
        })
  } else {
    store.dispatch('showError', {text: "Falscher Artikel", time: 6000})
  }

  router.push("/picken")
}

function padEAN(ean) {
  let result = ean;
  if (ean.length < 8) {
    result = padToLength(ean, 8);
  } else if (ean.length > 8 && ean.length < 13) {
    result = padToLength(ean, 13);
  }
  return result;
}

function padToLength(ean, length) {
  let result = ean;
  while (result.length < length) {
    result = "0" + result;
  }
  return result;
}

function noop() {

}

onMounted(() => {
  storageField.value?.focus()
})

const orderItem = computed({
  get() {
    return store.state.pickingItem.item.orderItem
  }
})

</script>

<template>
  <v-main class="mt-5 ml-5">
    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="'Picken'"></div>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="6" xl="6">
        <v-card>
          <v-card-text>
            <div v-if="orderItem.product.ean">EAN: {{ orderItem.product.ean }}</div>
            <div v-if="orderItem.product.title">Artikel: {{ orderItem.product.title }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col sm="10" md="6">
        <v-form ref="storageEntry" v-model="locationValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="storageLocation"
              ref="storageField"
              clearable
              dense
              outlined
              :label="'Lagerplatz scannen'"
              :rules="storageRules"
              @keyup.enter="doStorageLocationSearch"></v-text-field>
        </v-form>
      </v-col>
      <v-col sm="2">
        <v-btn color="secondary" tile :disabled="!locationValid" @click="doStorageLocationSearch">OK</v-btn>
      </v-col>

    </v-row>
    <v-row class="mt-5">

      <v-col class="flex-grow-1">
        <v-form ref="eanEntry" v-model="eanValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="ean"
              ref="eanField"
              clearable
              dense
              outlined
              inputmode="numeric"
              :label="'EAN'"
              :rules="eanRules"
              :disabled="!locationValid || !locationContainsProduct"
              @keyup.enter="confirmPick"></v-text-field>
        </v-form>

      </v-col>
      <v-col>
        <v-btn color="secondary" tile :disabled="!eanValid || !locationContainsProduct" @click="confirmPick">OK
        </v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<style scoped>

</style>