<script setup>

import {computed, onMounted, ref} from "vue";
import {useStore} from "@/store/store";
import ProductQuantityChanger from "@/components/ProductQuantityChanger.vue";
import validation from "@/util/validation";

const store = useStore()

const eanValid = ref(false)
const locationValid = ref(false)

const ean = ref("")
// the ref is matched by the variable name
const eanField = ref(null)

const storageLocation = ref("")
// the ref is matched by the variable name
const storageField = ref(null)

// the ref is matched by the variable name

const eanRules = [
  v => !!v || "Required",
]

const storagePattern = /(\d+)\.(\d+)\.?(\d+)*/
const storageRules = [
  v => !!v || "Required",
  v => storagePattern.test(v) || "Ungültiger Format (Gang.Platz.Box)",
]

let tableHeaders = [
  {
    text: "EAN",
    sortable: false,
    value: "ean"
  },
  {
    text: "Titel",
    sortable: false,
    value: "title"
  },
  {
    text: "Menge",
    sortable: false,
    value: "quantity"
  }
]

let storageLocations = computed(() => {
  let productsWithStorageLocations = store.state.productsWithStorageLocations

  const result = []

  for (const i in productsWithStorageLocations) {
    const product = productsWithStorageLocations[i]
    result.push({
      ean: product.ean,
      title: product.title,
      quantity: product.storageLocations[0].quantity
    })
  }

  return result

});

function doStorageLocationSearch() {
  if (locationValid.value) {
    eanField.value?.focus()

    let regExpExecArray = validation.storagePattern.exec(storageLocation.value);
    const coordinates = {
      corridor: regExpExecArray[1],
      shelf: regExpExecArray[2],
      box: regExpExecArray[3]
    }

    store.dispatch("queryStorageLocation", coordinates)

  }
}

function doMoveToTempStorage() {
  if (!eanValid.value || !locationValid.value) return

  let regExpExecArray = validation.storagePattern.exec(storageLocation.value);
  const coordinates = {
    corridor: regExpExecArray[1],
    shelf: regExpExecArray[2],
    box: regExpExecArray[3]
  }
  const request = {
    ean: ean.value,
    quantityChange: store.state.actualProductQuantity,
    originLocation: coordinates,
    destinationLocation: store.state.tempStorageLocation
  }
  store.dispatch("changeStorageLocation", request).then(handleOk).catch(noop)
  storageField.value?.focus()
}

function handleOk() {
  reset()
  store.dispatch('showInfo', {text: "OK", time: 1000})
}

function reset() {
  store.commit("setProduct", {})
  store.commit("setActualProductQuantity", 1)
  store.commit("setProductsWithStorageLocations", [])
  ean.value = ""
  storageLocation.value = ""

}

function noop() {
}

// hooks
onMounted(() => {
  reset()

  if (!store.state.tempStorageLocation) {
    store.dispatch("loadTempStorage")
  }

  storageField.value?.focus()
})


</script>

<template>
  <v-main class="mt-5 ml-5 mr-5">
    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="'Auslagern'"></div>
    </v-row>
    <v-row class="mt-5">
        <v-col sm="8" md="8" lg="8" xl="4">
          <v-form ref="storageEntry" v-model="locationValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="storageLocation"
              ref="storageField"
              clearable
              dense
              outlined
              :label="'Lagerplatz scannen'"
              :rules="storageRules"
              @keyup.enter="doStorageLocationSearch"></v-text-field>
          </v-form>
        </v-col>
    </v-row>

    <v-row v-if="locationValid">
      <v-col sm="8" md="8" lg="8" xl="4">
        <v-data-table
            disable-pagination
            :hide-default-footer="true"
            no-data-text="noch nicht im Lager"
            :headers="tableHeaders"
            :items="storageLocations"
            class="elevation-1"
            :mobile-breakpoint="0"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" md="8" lg="8" xl="4">
        <ProductQuantityChanger/>
      </v-col>
    </v-row>

    <v-row class="mt-5">

        <v-col sm="8" md="8" lg="8" xl="4">
          <v-form ref="eanEntry" v-model="eanValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="ean"
              ref="eanField"
              clearable
              dense
              outlined
              inputmode="numeric"
              :label="'EAN'"
              :rules="eanRules"
              :disabled="!locationValid"
              @keyup.enter="doMoveToTempStorage"></v-text-field>
          </v-form>
        </v-col>

    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" tile :disabled="!eanValid || !locationValid" @click="doMoveToTempStorage">Auslagern
        </v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<style scoped>

</style>