export default {
    formatStorageMessage: function (ean, locationCoordinates, quantity, destinationLocation) {
        let message = "<br><br>EAN <b>" + ean + "</b><br>Menge <b>" + quantity + "</b><br>Lagerplatz <b>" + locationCoordinates + "</b>"
        if (destinationLocation) {
            message += " -> <b>" + destinationLocation + "</b>"
        }
        return message
    },
    formatStorageLocation: function (storageLocationCoordinates) {
        let message = storageLocationCoordinates.corridor + "." + storageLocationCoordinates.shelf;
        if (storageLocationCoordinates.box) {
            message += "." + storageLocationCoordinates.box
        }
        return message
    },
    isEqualLocation: function (a, b) {
        return a.corridor === b.corridor && a.shelf === b.shelf && a.box === b.box
    }
}