<script setup>

import {onMounted, ref} from "vue"
import backendInterface from "@/util/interface";
import {useStore} from "@/store/store";
import {useRouter} from "@/routes";

const valid = ref(false)
const ean = ref("")
// the ref is matched by the variable name
const eanField = ref(null)

const eanRules = [
  v => !!v || "Required",
]

let store = useStore()
let router = useRouter()

// hooks
onMounted(() => {
  store.commit("setProduct", {})
  eanField.value?.focus();
})

function doProductSearch(ean) {
  store
      .dispatch("loadProductV2", ean)
      .then(function (productResponse) {
        if (productResponse.code === backendInterface.codes.ProductFoundInBillbee) {
          // we can proceed to the normal flow
          router.push("/einbuchen/" + ean)
        }
      })
      .catch(function () {
        console.log("error")
        router.push('/home');
      })
}

function noop() {
  // surprisingly does nothing
}

</script>

<template>
  <v-main class="ml-5 mt-5">
    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="'Einbuchen'"></div>
    </v-row>
    <v-row class="mt-5">
      <v-col sm="8" md="8" lg="4" xl="2">
        <v-form ref="eanEntry" v-model="valid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="ean"
              ref="eanField"
              clearable
              dense
              outlined
              inputmode="numeric"
              :label="'EAN'"
              :rules="eanRules"
              @keyup.enter="doProductSearch(ean)"></v-text-field>
        </v-form>
      </v-col>

      <v-col>
        <v-btn color="secondary" tile :disabled="!valid" @click="doProductSearch(ean)">Suchen</v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<style scoped>

</style>