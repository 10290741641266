<script setup>
import {useStore} from "@/store/store";

const store = useStore()

function changeQuantity() {
  store.commit("openQuantityChangeDialog")
}

</script>

<template>
<!--  todo formatting-->
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="text-h6"><div style="display:flex;height: 100%; align-items: center">Menge</div></v-col>
          <v-col cols="4"><div class="quantityContainer"><span class="font-weight-bold" style="font-size: 50px">{{store.state.actualProductQuantity}}</span><a @click="changeQuantity">Ändern</a></div></v-col>
        </v-row>

      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.quantityContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px
}
</style>