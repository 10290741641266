import { render, staticRenderFns } from "./PriceLabelCreation.vue?vue&type=template&id=d0b0bdac&scoped=true"
import script from "./PriceLabelCreation.vue?vue&type=script&setup=true&lang=js"
export * from "./PriceLabelCreation.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0b0bdac",
  null
  
)

export default component.exports