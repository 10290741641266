<template>
  <v-main>
    <v-container class="pa-8">
      <v-row class="flex-grow-1 mb-1 pl-3">
        <div class="text-h4 transition-swing" v-text="text"></div>
      </v-row>
      <v-row v-if="isMobile">
        <v-col>
          <ScanditScanner :on-detected="eanScanned"></ScanditScanner>
        </v-col>
      </v-row>

      <v-row>
        <v-form ref="eanEntry" v-model="valid" v-on:submit.prevent="noop">
          <v-col class="flex-grow-1">
            <v-text-field
                v-model="ean"
                ref="ean"
                clearable
                dense
                outlined
                inputmode="numeric"
                :label="'EAN'"
                :rules="eanRules"
                @keyup.enter="eanFound(ean)"></v-text-field>
          </v-col>
        </v-form>
        <v-col>
          <v-btn color="secondary" tile :disabled="!valid" @click="eanFound(ean)">Suchen</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Item Picken
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pt-5 text-h4">
          <div>
            Gepickte Menge = {{ $store.state.pickingItem.item.quantity }}
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="confirmPicking"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import backendInterface from "@/util/interface";
import ScanditScanner from "@/components/ScanditScanner";


export default {
  name: "EANEntry",
  components: {
    ScanditScanner
  },
  data() {
    return {
      text: this.$store.state.actualFlow.text,
      valid: false,
      eanRules: [
        v => !!v || "Required",
      ],
      dialog: false
    }
  },
  mounted() {
    this.$store.commit("setEan", "")
    if (this.$route.path === "/ausbuchen") {
      this.$store.commit("setActualFlow", {
        text: "Ausbuchen",
        flow: "ausbuchen",
        icon: "minus.svg"
      });
    } else if (this.$route.path === "/einbuchenalt") {
      this.$store.commit("setActualFlow", {
        text: "Einbuchen",
        flow: "einbuchenalt",
        icon: "add.svg"
      });
    }

    if (!this.isMobile) {
      this.$refs["ean"].focus();
    }
  },
  computed: {
    ean: {
      get() {
        return this.$store.state.ean
      },
      set(value) {
        this.$store.commit("setEan", value)
      }
    },
    readerSize() {
      return {
        width: 300,
        height: 300
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    eanScanned(result) {
      if (process.env.VUE_APP_DEBUG_MODE) console.log("EAN: " + result)
      this.$store.commit("setEan", result)
      this.eanFound(result)
    },
    eanFound(ean) {
      if (process.env.VUE_APP_DEBUG_MODE) console.log("Starting eanSearch " + ean)

      if (this.$store.state.actualFlow.flow === "pickenalt") {
        if (this.$store.state.pickingItem.item.quantity > 1) {
          // show dialog if multiple items are being picked
          this.dialog = true
        } else {
          // directly pick single items
          this.doPickItem(ean);
        }
      }
    },
    confirmPicking() {
      this.doPickItem(this.ean);
    },
    doPickItem(ean) {
      const self = this
      ean = this.padEAN(ean)
      if (ean === this.$store.state.pickingItem.item.product.ean) {
        console.log("article correctly picked")
        this.$store
            .dispatch('pickItem')
            .then(function (itemPickResponse) {
              if (itemPickResponse.code === backendInterface.codes.ItemPicked) {
                self.$store.dispatch('showInfo', {text: "Artikel erfolgreich gepickt", time: 6000})
              } else if (itemPickResponse.code === backendInterface.codes.ItemPickedAndOrderUpdated) {
                self.$store.dispatch('showInfo', {
                  text: "Artikel erfolgreich gepickt und Bestellung aktualisiert",
                  time: 3000
                })
              } else if (itemPickResponse.code === backendInterface.codes.ItemAlreadyPicked) {
                self.$store.dispatch('showError', {text: "Artikel bereits gepickt", time: 6000})
              }
            })
      } else {
        console.log("wrong article")
        this.$store.dispatch('showError', {text: "Falscher Artikel", time: 6000})
      }

      this.$router.push("/pickenalt")
    },
    padEAN(ean) {
      let result = ean;
      if (ean.length < 8) {
        result = this.padToLength(ean, 8);
      } else if (ean.length > 8 && ean.length < 13) {
        result = this.padToLength(ean, 13);
      }
      return result;
    },
    padToLength(ean, length) {
      let result = ean;
      while (result.length < length) {
        result = "0" + result;
      }
      return result;
    },
    scanError(err) {
      this.$store.dispatch("showError", {text: err, time: 3000})
    },
    noop() {
      // surprisingly do nothing
    }
  }
}
</script>

<style scoped>

</style>