<script setup>

import {useStore} from "@/store/store";
import axios from "@/plugins/axios";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "@/routes";
import StorageLocationsTable from "@/components/StorageLocationsTable.vue";

const store = useStore()
const router = useRouter()

let sellingPlatforms = ref([])
sellingPlatforms.value.push("Alle")

let shippingProviders = ref([])


const orders = computed({
  get() {
    return store.state.pagedOrders.orders
  }
})

const totalPages = computed({
  get() {
    return store.state.pagedOrders.pagination.totalPages
  }
})

const selectedSellingPlatform = computed({
  get() {
    return store.state.selectedSellingPlatform
  },
  set(newSellingPlatform) {
    store.commit('setSelectedSellingPlatform', newSellingPlatform)
  }
})

const selectedShippingProviders = computed({
  get() {
    return store.state.selectedShippingProviders
  },
  set(newSelectedShippingProviders) {
    store.commit('setSelectedShippingProviders', newSelectedShippingProviders)
  }
})

const actualPage = computed({
  get() {
    return store.state.ordersActualPage
  },
  set(newActualPage) {
    store.commit('setOrdersActualPage', newActualPage)
  }
})

const includeStoreOnlyOrders = computed({
  get() {
    return store.state.includeStoreOnlyOrders
  },
  set(newIncludeStoreOnlyOrders) {
    store.commit('setIncludeStoreOnlyOrders', newIncludeStoreOnlyOrders)
  }
})

const allShippingProvidersSelected = computed({
  get() {
    return shippingProviders.value.every(v => selectedShippingProviders.value.includes(v))
  },
  set(newValue) {
    if (newValue) {
      selectedShippingProviders.value = shippingProviders.value
      searchParametersChanged()
    }
  }
})

onMounted(() => {
  let currentPage = actualPage.value
  if (store.state.pagedOrders.pagination && totalPages.value < currentPage) {
    // one page disappeared when loading the list
    currentPage = totalPages.value
    console.log("setting currentPage", currentPage)
  }
  const sellingPlatform = selectedSellingPlatform.value;

  axios.get("/api/v2/orders/sellingPlatforms")
      .then(function (res) {
        res.data.forEach(p => sellingPlatforms.value.push(p))
      });

  axios.get("/api/v2/orders/shippingProviders")
      .then(function (res) {
        res.data.forEach(p => {
          shippingProviders.value.push(p)
        })

        if (selectedShippingProviders.value == null) {
          selectedShippingProviders.value = shippingProviders.value
        }
      })

  store.dispatch("loadOrdersV2", {
    actualPage: currentPage,
    sellingPlatform,
    shippingProviders: selectedShippingProviders.value,
    includeStoreOnlyOrders: includeStoreOnlyOrders.value
  })
})

function changeSellingPlatform(sellingPlatform) {
  store.commit("setOrdersActualPage", 1)
  store
      .dispatch("loadOrdersV2", {
        actualPage: 1,
        sellingPlatform,
        shippingProviders: selectedShippingProviders.value,
        includeStoreOnlyOrders: includeStoreOnlyOrders.value
      })
}

function checkboxChanged() {
  searchParametersChanged()
}

function searchParametersChanged() {
  store.commit("setOrdersActualPage", 1)
  store
      .dispatch("loadOrdersV2", {
        actualPage: 1,
        sellingPlatform: selectedSellingPlatform.value,
        shippingProviders: selectedShippingProviders.value,
        includeStoreOnlyOrders: includeStoreOnlyOrders.value
      })
}

function changePage(actualPage) {
  let sellingPlatform = selectedSellingPlatform.value;
  store
      .dispatch("loadOrdersV2", {
        actualPage,
        sellingPlatform,
        shippingProviders: selectedShippingProviders.value,
        includeStoreOnlyOrders: includeStoreOnlyOrders.value
      })
}

function dateChipColor(order) {
  if (dateIsBeforeToday(order.createdAt)) {
    return "secondary"
  }
  return "grey"

}

function dateChipOutlined(order) {
  if (dateIsBeforeToday(order.createdAt)) {
    return false
  }
  return true
}

function dateIsBeforeToday(date) {
  return new Date(date) < new Date(new Date().toDateString());
}

function getDefaultThumbUrl(images) {
  let defaultImage = images.find(i => i.default);
  if (!defaultImage) return null
  return defaultImage.url
}

function pickItem(order, item) {
  store.commit("setPickingItem", {order, item})

  router.push("/picken/" + order.orderNumber);
}

</script>

<template>
  <v-main>
    <v-container class="justify-center pt-10">
      <v-row justify="center">
        <v-col justify="center" xs="2" sm="4" xl="4">
          <v-select v-if="store.state.pagedOrders.orders"
                    dense
                    :items="sellingPlatforms"
                    label="Shop"
                    v-model="selectedSellingPlatform"
                    @change="changeSellingPlatform"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pb-5" v-if="shippingProviders">
      <v-row justify="center">
        <v-checkbox label="Alle" key="Alle" v-model="allShippingProvidersSelected"/>
        <v-checkbox v-for="shippingProvider in shippingProviders"
                    v-model="selectedShippingProviders"
                    :key="shippingProvider"
                    :value="shippingProvider"
                    :label="shippingProvider"
                    class="ml-5"
                    @change="checkboxChanged"
        />
        <v-checkbox class="ml-5" label="900" key="storeStorage" v-model="includeStoreOnlyOrders" @change="checkboxChanged"/>
      </v-row>
      <v-row justify="center">
        <p>Noch zu picken (Alle): {{ store.state.pagedOrders.allOrdersCount }}</p>
      </v-row>
    </v-container>
    <v-card v-for="order in orders" :key="order.id">
      <v-card-title>
        <div v-text="order.orderNumber"></div>
      </v-card-title>
      <v-card-subtitle>
        <v-chip small color="primary">{{ order.sellingPlatform }}</v-chip>
        <v-chip small color="lightgrey">{{ order.shippingProviderProductName }}</v-chip>
        <v-chip small text-color="black" :outlined="dateChipOutlined(order)" :color="dateChipColor(order)">
          {{ order.localizedCreatedAtDate }}
        </v-chip>
      </v-card-subtitle>
      <v-card-text>
        <v-card v-for="item in order.pickingItems" :key="item.billbeeId">
          <v-card-title>
            <div v-text="item.orderItem.product.title"></div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="flex-grow-1" :align="'start'">
                <v-col cols="8">
                  <div v-if="item.orderItem.product.ean">EAN: {{ item.orderItem.product.ean }}</div>
                  <div v-if="item.orderItem.product.sku">SKU: {{ item.orderItem.product.sku }}</div>
                  <div v-if="item.orderItem.product.size">Größe: {{ item.orderItem.product.size }}</div>
                  <div v-if="item.orderItem.product.color">Farbe: {{ item.orderItem.product.color }}</div>
                  <div v-if="item.orderItem.product.category1">Kategorie: {{ item.orderItem.product.category1 }}</div>
                </v-col>
                <v-col cols="4">
                  <v-img
                      v-if="getDefaultThumbUrl(item.orderItem.product.images)"
                      height="100"
                      contain
                      :src="getDefaultThumbUrl(item.orderItem.product.images)"></v-img>
                </v-col>
              </v-row>
              <v-row class="flex-grow-1">
                <v-col sm="8" md="6" lg="5" xl="5">
                  <StorageLocationsTable :items="item.orderItem.storageLocations"/>
                </v-col>
                <v-col sm="4" md="4" lg="2" xl="2">
                  <v-btn block :color="item.status === 'Picked' ? 'green' : 'secondary'"
                         :disabled="item.status === 'Picked'" @click="pickItem(order, item)">Pick
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
    <v-container v-if="orders.length === 0" fill-height>
      <v-row justify="center">
        <div class="text-h5" :text="'Keine Bestellung zu Picken'">Keine Bestellung zu Picken</div>
      </v-row>
    </v-container>
    <v-container class="pt-5 pb-5">
      <v-pagination v-if="store.state.pagedOrders.pagination"
                    :length="totalPages"
                    v-model="actualPage"
                    @input="changePage"
      />
    </v-container>
  </v-main>
</template>

<style scoped>

</style>