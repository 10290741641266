<template>
  <v-main>
    <v-container class="justify-center pt-10">
      <v-row justify="center">
        <v-col justify="center" xs="2" sm="4" xl="4">
          <v-select v-if="$store.state.pagedOrders.orders"
                    dense
                    :items="sellingPlatforms"
                    label="Shop"
                    v-model="selectedSellingPlatform"
                    @change="changeSellingPlatform"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pb-5">
      <v-pagination v-if="$store.state.pagedOrders.pagination"
                    :length="totalPages"
                    v-model="actualPage"
                    @input="changePage"
      />
    </v-container>
    <v-card v-for="order in orders" :key="order.id">
      <v-card-title>
        <div v-text="order.orderNumber"></div>
      </v-card-title>
      <v-card-subtitle>
        <v-chip small color="primary">{{ order.sellingPlatform }}</v-chip>
        <v-chip small color="lightgrey">{{order.shippingProviderProductName}}</v-chip>
        <v-chip small text-color="black" :outlined="dateChipOutlined(order)" :color="dateChipColor(order)">{{order.localizedCreatedAtDate}}</v-chip>
      </v-card-subtitle>
      <v-card-text>
        <v-card v-for="item in order.orderItems" :key="item.billbeeId">
          <v-card-title>
            <div v-text="item.product.title"></div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="flex-grow-1" :align="'start'">
                <v-col cols="8">
                  <div v-if="item.product.ean">EAN: {{ item.product.ean }}</div>
                  <div v-if="item.product.sku">SKU: {{ item.product.sku }}</div>
                  <div v-if="item.totalPrice">Preis: {{ item.totalPrice }}€</div>
                  <div v-if="item.product.size">Größe: {{ item.product.size }}</div>
                  <div v-if="item.product.color">Farbe: {{ item.product.color }}</div>
                  <div v-if="item.product.extraDataLoaded">Lagerbestand (nach Bestellung): {{ item.product.stock }}</div>
                  <div v-if="item.product.category1">Kategorie: {{ item.product.category1 }}</div>
                  <div v-if="!item.product.extraDataLoaded && item.product.ean"><v-btn text dense color="primary" @click="loadDetails(item)">mehr anzeigen</v-btn></div>
                </v-col>
                <v-col cols="4">
                  <v-img
                      v-if="getDefaultThumbUrl(item.product.images)"
                      height="100"
                      contain
                      :src="getDefaultThumbUrl(item.product.images)"></v-img>
                </v-col>
              </v-row>
              <v-row class="flex-grow-1" :justify="'end'">
                <v-spacer>
                </v-spacer>
                <v-col cols="3" :justify="'end'">
                  <div class="text-h4 font-weight-bold"
                       :style="[item.quantity > 1 ? {'color': 'red'} : {'color': 'black'}]"
                       v-if="item.quantity">{{ item.quantity }}x
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-btn block :color="item.status === 'Picked' ? 'green' : 'secondary'"
                         :disabled="item.status === 'Picked'" @click="pickItem(order, item)">Pick
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
    <v-container v-if="orders.length === 0" fill-height>
      <v-row justify="center">
        <div class="text-h5" :text="'Keine Bestellung zu Picken'">Keine Bestellung zu Picken</div>
      </v-row>
    </v-container>
    <v-container class="pt-5 pb-5">
      <v-pagination v-if="$store.state.pagedOrders.pagination"
                    :length="totalPages"
                    v-model="actualPage"
                    @input="changePage"
      />
    </v-container>
  </v-main>
</template>

<script>
import axios from '../plugins/axios'
import backendInterface from '../util/interface'

export default {
  name: "OrdersOverview",
  data() {
    return {
      sellingPlatforms: ["Alle"]
    }
  },
  computed: {
    orders: function () {
      return this.$store.state.pagedOrders.orders
    },
    totalPages() {
      return this.$store.state.pagedOrders.pagination.totalPages
    },
    selectedSellingPlatform: {
      get() {
        return this.$store.state.selectedSellingPlatform
      },
      set(newSellingPlatform) {
        this.$store.commit('setSelectedSellingPlatform', newSellingPlatform)
      }
    },
    actualPage: {
      get() {
        return this.$store.state.ordersActualPage
      },
      set(newActualPage) {
        this.$store.commit('setOrdersActualPage', newActualPage)
      }
    }
  },
  methods: {
    pickItem(order, item) {
      this.$store.commit("setPickingItem", {order, item})

      this.$router.push("/pickenalt/" + order.orderNumber);
    },
    getDefaultThumbUrl(images) {
      let defaultImage = images.find(i => i.default);
      if (!defaultImage) return null
      return defaultImage.url
    },
    changeSellingPlatform(sellingPlatform) {
      this.$store.commit("setOrdersActualPage", 1)
      this.$store
          .dispatch("loadOrders", {actualPage: 1, sellingPlatform})
          .then(this.processOrders)
    },
    changePage(actualPage) {
      let sellingPlatform = this.selectedSellingPlatform;
      this.$store
          .dispatch("loadOrders", {actualPage, sellingPlatform})
          .then(this.processOrders)
    },
    processOrders(ordersResponse) {
      const orders = ordersResponse.orders
      for (const orderIndex in orders) {
        const order = orders[orderIndex]
        for (const i in order.orderItems) {
          const orderItem = order.orderItems[i];
          let product = orderItem.product
          if (!product.imagesLoaded) {
            axios
                .get("/api/products/" + product.billbeeId + "/images")
                .then(function (res) {
                  product.imagesLoaded = true
                  product.images = res.data
                })
          }
        }
      }
    },
    loadDetails(item) {
      const product = item.product
      if (!product.ean) return
      axios
          .get("/api/products/" + product.ean + "/lite")
          .then(function (res) {
            const productLiteResponse = res.data
            if (productLiteResponse !== null && productLiteResponse.code === backendInterface.codes.ProductFoundInBillbee) {
              const productLite = productLiteResponse.data;
              product.extraDataLoaded = true
              product.images = productLite.images
              product.color = productLite.color
              product.size = productLite.size
              product.stock = productLite.stock
              product.category1 = productLite.category1
            }
          })
    },
    dateChipColor(order) {
      if (this.dateIsBeforeToday(order.createdAt)) {
        return "secondary"
      }
      return "grey"

    },
    dateChipOutlined(order) {
      if (this.dateIsBeforeToday(order.createdAt)) {
        return false
      }
      return true
    },
    dateIsBeforeToday(date) {
      return new Date(date) < new Date(new Date().toDateString());
    }
  },
  mounted() {
    this.$store.commit("setActualFlow", {
      text: "Picken",
      flow: "pickenalt",
      icon: "trolley.svg",
      color: "secondary"
    });

    let actualPage = this.actualPage
    if (this.$store.state.pagedOrders.pagination && this.totalPages < actualPage)
    {
      // one page disappeared when loading the list
      actualPage = this.totalPages
    }

    const sellingPlatform = this.selectedSellingPlatform;
    this.$store
        .dispatch("loadOrders", {actualPage, sellingPlatform})
        .then(this.processOrders)

    let result = []
    result.push("Alle")
    axios
        .get("/api/orders/sellingPlatforms")
        .then(function (res) {
          let sellingPlatforms = res.data;
          sellingPlatforms.forEach(p => result.push(p))
        })
    this.sellingPlatforms = result;
  }
}
</script>

<style scoped>

</style>