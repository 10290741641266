<template>
    <v-main>
        <v-container class="pa-8">
            <v-row v-if="isMobile">
                <v-col>
                    <ScanditScanner :on-detected="eanScanned"></ScanditScanner>
                </v-col>
            </v-row>

            <v-form ref="eanEntry" v-model="valid" v-on:submit.prevent="noop">
                <v-row>
                    <v-col class="flex-grow-1">
                        <v-text-field
                                v-model="ean"
                                ref="ean"
                                clearable
                                dense
                                outlined
                                inputmode="numeric"
                                :label="'EAN'"
                                :rules="eanRules"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="flex-grow-1">
                        <v-text-field
                                v-model="quantity"
                                ref="quantity"
                                clearable
                                dense
                                outlined
                                inputmode="numeric"
                                :label="'Anzahl'"
                                :rules="eanRules"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn color="secondary" tile :disabled="!valid" @click="createLabelAndPrint">Label Drucken</v-btn>
                    </v-col>
                </v-row>
            </v-form>

        </v-container>
    </v-main>
</template>

<script>
import ScanditScanner from "@/components/ScanditScanner";


export default {
    name: "EANLabelCreation",
    components: {
        ScanditScanner
    },
    data() {
        return {
            text: this.$store.state.actualFlow.text,
            ean: "",
            quantity: 1,
            valid: false,
            eanRules: [
                v => !!v || "Required",
            ]
        }
    },
    mounted() {
        if (!this.isMobile) {
            this.$refs["ean"].focus();
        }
    },
    computed: {
        readerSize() {
            return {
                width: 300,
                height: 300
            }
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        eanScanned(result) {
            this.ean = result
        },
        createLabelAndPrint() {
            const self = this
            this.$store
                .dispatch("createLabelAndPrint", {ean: this.padEAN(this.ean), quantity: this.quantity})
                .then(function () {
                    self.quantity = 1
                    self.ean = ""
                })
        },
        padEAN(ean) {
            let result = ean;
            if (ean.length < 8) {
                result = this.padToLength(ean, 8);
            } else if (ean.length > 8 && ean.length < 13) {
                result = this.padToLength(ean, 13);
            }
            return result;
        },
        padToLength(ean, length) {
            let result = ean;
            while (result.length < length) {
                result = "0" + result;
            }
            return result;
        },
        scanError(err) {
            this.$store.dispatch("showError", {text: err, time: 3000})
        },
        noop() {
            // surprisingly do nothing
        }
    }
}
</script>

<style scoped>

</style>