<template>
  <v-main>
    <v-container class="pa-8 mt-1 mb-2">
      <v-row class="flex-grow-1 mb-1">
        <div class="text-h4 transition-swing" v-text="title"></div>
      </v-row>
      <v-row justify="center">
        <ProductDetails v-if="product.data !== undefined"/>
      </v-row>
      <v-row :justify="'start'">
        <v-btn dense text @click="changePrice" color="primary">
          {{ 'Preis ändern' }}
        </v-btn>
      </v-row>
      <v-row>
        <StockChange v-if="product.data !== undefined" v-on:stock-changed="onStockChangedAndHome"
                     v-on:stock-changed-and-new="onStockChangedAndNew"/>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ProductDetails from "@/components/ProductDetails";
import StockChange from "@/components/StockChange";

export default {
  name: "ProductEdit",
  components: {
    StockChange,
    ProductDetails
  },
  data() {
    return {
      backArrow: false,
    }
  },
  computed: {
    product: {
      get() {
        return this.$store.state.product
      }
    },
    title: {
      get() {
        return this.$store.state.actualFlow.text
      }
    }
  },
  methods: {
    onStockChanged(newStock) {
      return this.$store.dispatch("updateStock", newStock)
    },
    onStockChangedAndHome(newStock) {
      const self = this
      this.onStockChanged(newStock)
          .then(function () {
            self.$store.commit("setEan", "");
            self.$router.push("/")
          })
    },
    onStockChangedAndNew(newStock) {
      const self = this
      this.onStockChanged(newStock)
          .then(function () {
            self.$store.commit("setEan", "");
            self.$router.push("/" + self.$store.state.actualFlow.flow)
          })
    },
    changePrice() {
      this.$store.commit("setActualFlow", {
        text: "Preis Ändern",
        flow: "preis",
        icon: "tag.png",
        color: "secondary"
      });
      this.$router.push('/preis/' + this.$store.state.ean)
    }
  },
  mounted() {
    const self = this
    if (this.$store.state.ean === "" && this.$route.params['ean'] !== "undefined") {
      const ean = this.$route.params['ean']
      // needed if the site is reloaded
      this.$store.commit("setEan", ean);
      if (this.$route.path.includes("/ausbuchen")) {
        this.$store.commit("setActualFlow", {
          text: "Ausbuchen",
          flow: "ausbuchen",
          icon: "minus.svg"
        });
      } else if (this.$route.path.includes("/einbuchenalt")) {
        this.$store.commit("setActualFlow", {
          text: "Einbuchen",
          flow: "einbuchenalt",
          icon: "add.svg"
        });
      }

      this.$store.dispatch("loadProduct", ean)
          .catch(function () {
            // on error go back to EAN Entry
            self.$router.push("/" + self.$store.state.actualFlow.flow)
          })
    }
  }
}
</script>

<style scoped>

</style>