<script setup>

import {useStore} from "@/store/store";
import {computed, onMounted, ref, watch} from "vue";

let store = useStore();
const quantityChangeDialog = store.state.quantityChangeDialog
const valid = ref(true)
const quantity = ref(store.state.actualProductQuantity)
const quantityField = ref(null)

const requiredNumber = [
  v => !!v || "Required",
  v => v > 0 || "Ungültige Zahl"
]

function confirmChange() {
  store.commit("setActualProductQuantity", quantity.value)
  hideDialog()
}

function hideDialog() {
  store.commit("hideQuantityChangeDialog")
}

onMounted(() => {
  quantityField.value?.focus()
})

const visible = computed({
  get() {
    return store.state.quantityChangeDialog.visible
  }
})

watch(visible, (newVal, oldVal) => {
  if (newVal && !oldVal) {
    quantityField.value?.focus()
  }
})

</script>

<template>
  <v-dialog
      v-if="quantityChangeDialog.visible"
      v-model="quantityChangeDialog.visible"
      color="primary"
      persistent
      max-width="600"
      transition="fade-transition"
  >
    <!--    bigger -->
    <v-card class="pt-5">
      <v-card-text>
        <v-form v-model="valid" v-on:submit.prevent="() => {}">
          <v-text-field
              v-model="quantity"
              ref="quantityField"
              clearable
              dense
              outlined
              inputmode="numeric"
              :label="'Menge'"
              :rules="requiredNumber"></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider/>
      <v-card-actions>
        <v-btn
            :disabled="!valid"
            class="no-text-transform btn__p100"
            color="primary darken-1"
            text
            @click="confirmChange"
        >
         <span style="white-space: normal;">
           OK
         </span>
        </v-btn>
        <v-btn
            class="no-text-transform btn__p100"
            color="red"
            text
            @click="hideDialog"
        >
         <span style="white-space: normal;">
           Abbrechen
         </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>