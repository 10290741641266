<template>
  <v-container>
    <v-row class="mt-0" :align="'start'">
        <div>
          <div class="text-h5">Mengenänderung <img :alt="$store.state.actualFlow.text" :src="require('../assets/' + $store.state.actualFlow.icon)" height="15px"/></div>
        </div>
    </v-row>
    <v-row class="mt-5">
      <div class="text-h6">Aktueller Bestand: {{ actualStock }}</div>
    </v-row>
    <v-row class="flex-grow-1 mb-3" :justify="'end'" :align="'center'" >
      <v-col class="hidden-xs-only" cols="2">
        <div v-text="'Menge'"></div>
      </v-col>
      <v-col class="d-flex">
        <v-btn @click="stockChange += 1" color="primary">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-text-field
            class="ml-2 mr-2"
            dense
            outlined
            inputmode="numeric"
            v-model="stockChange"
            hide-details
            :label="'Menge'"
        ></v-text-field>
        <v-btn @click="stockChange -= 1" :disabled="stockChange === 0" color="primary">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row></v-row>
    <v-row class="mt-5">
      <div class="text-h6">Neuer Bestand: {{ newStock }}</div>
    </v-row>
    <v-row :align="'end'" class="flex-grow-1 mt-5">
      <v-col>
        <v-btn class="pr-2"
               :height="70"
               block
               color="secondary"
               :disabled="newStock === actualStock || !isValidNumber(newStock)"
               @click="$emit('stock-changed', newStock)">Lagerbestand ändern
        </v-btn>
      </v-col>
      <v-col>
        <v-btn class="pl-2"
               :height="70"
               block
               color="primary"
               :disabled="newStock === actualStock || !isValidNumber(newStock)"
               @click="$emit('stock-changed-and-new', newStock)">Lagerbestand ändern + neu
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StockChange",
  data () {
    return {
      stockChange: 1
    }
  },
  computed: {
    actualStock() {
      return this.$store.state.product.data.stock
    },
    newStock() {
      const actualFlow = this.$store.state.actualFlow;
      if (actualFlow.flow === "ausbuchen") {
        return +this.actualStock - +this.stockChange;
      }
      else if (actualFlow.flow === "einbuchenalt") {
        return +this.actualStock + +this.stockChange;
      }

      return "";
    },
    icon() {
      return this.$store.state.actualFlow.icon
    }
  },
  methods: {
    isValidNumber(number) {
      if (!Number.isInteger(number)) return false;
      if (number < 0) return false;
      return true
    }
  }
}
</script>

<style scoped>

</style>