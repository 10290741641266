export default {
    "codes": {
        ProductFoundInBillbee: "ProductFoundInBillbee",
        ProductFoundInAmazon: "ProductFoundInAmazon",
        ProductNotFound: "ProductNotFound",
        ProductCreated: "ProductCreated",
        ProductCouldNotBeCreated: "ProductCouldNotBeCreated",
        StockUpdated: "StockUpdated",

        ItemPicked: "ItemPicked",
        ItemPickedAndOrderUpdated: "ItemPickedAndOrderUpdated",
        ItemAlreadyPicked: "ItemAlreadyPicked",

        TechnicalError: "TechnicalError"
    }
}