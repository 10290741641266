<template>
  <v-main>
    <v-container class="pa-8">
      <v-row class="flex-grow-1 mb-1 pl-3">
        <div class="text-h4 transition-swing" v-text="text"></div>
      </v-row>
      <v-row v-if="isMobile">
        <v-col>
          <ScanditScanner :on-detected="eanScanned"></ScanditScanner>
        </v-col>
      </v-row>

      <v-row>
        <v-form ref="eanEntry" v-model="valid" v-on:submit.prevent="noop">
          <v-col class="flex-grow-1">
            <v-text-field
                v-model="ean"
                ref="ean"
                clearable
                dense
                outlined
                inputmode="numeric"
                :label="'EAN'"
                :rules="eanRules"
                @keyup.enter="eanFound(ean)"></v-text-field>
          </v-col>
        </v-form>
        <v-col>
          <v-btn color="secondary" tile :disabled="!valid" @click="eanFound(ean)">Suchen</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import backendInterface from "@/util/interface";
import ScanditScanner from "@/components/ScanditScanner";


export default {
  name: "EANEntry",
  components: {
    ScanditScanner
  },
  data() {
    return {
      text: this.$store.state.actualFlow.text,
      valid: false,
      eanRules: [
        v => !!v || "Required",
      ]
    }
  },
  mounted() {
    this.$store.commit("setEan", "")
    if (this.$route.path === "/ausbuchen") {
      this.$store.commit("setActualFlow", {
        text: "Ausbuchen",
        flow: "ausbuchen",
        icon: "minus.svg"
      });
    } else if (this.$route.path === "/einbuchenalt") {
      this.$store.commit("setActualFlow", {
        text: "Einbuchen",
        flow: "einbuchenalt",
        icon: "add.svg"
      });
    }

    if (!this.isMobile) {
      this.$refs["ean"].focus();
    }
  },
  computed: {
    ean: {
      get() {
        return this.$store.state.ean
      },
      set(value) {
        this.$store.commit("setEan", value)
      }
    },
    readerSize() {
      return {
        width: 300,
        height: 300
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    eanScanned(result) {
      if (process.env.VUE_APP_DEBUG_MODE) console.log("EAN: " + result)
      this.$store.commit("setEan", result)
      this.eanFound(result)
    },
    eanFound(ean) {
      if (process.env.VUE_APP_DEBUG_MODE) console.log("Starting eanSearch " + ean)

      this.doProductSearch(ean);
    },
    doProductSearch(ean) {
      const self = this
      this.$store
          .dispatch("loadProduct", ean)
          .then(function (productResponse) {
            if (productResponse.code === backendInterface.codes.ProductFoundInBillbee) {
              // we can proceed to the normal flow
              self.$router.push("/" + self.$store.state.actualFlow.flow + "/" + ean)
            } else if (productResponse.code === backendInterface.codes.ProductFoundInAmazon) {
              // we have to create the article first only in Einbuchen flow
              if (self.$store.state.actualFlow.flow === "einbuchenalt") {
                self.$router.push("/neu" + "/" + ean)
              } else {
                self.$store.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
              }
            }
          })
          .catch(function () {
            console.log("error")
            self.$router.push('/homealt');
          })
    },
    padEAN(ean) {
      let result = ean;
      if (ean.length < 8) {
        result = this.padToLength(ean, 8);
      } else if (ean.length > 8 && ean.length < 13) {
        result = this.padToLength(ean, 13);
      }
      return result;
    },
    padToLength(ean, length) {
      let result = ean;
      while (result.length < length) {
        result = "0" + result;
      }
      return result;
    },
    scanError(err) {
      this.$store.dispatch("showError", {text: err, time: 3000})
    },
    noop() {
      // surprisingly do nothing
    }
  }
}
</script>

<style scoped>

</style>