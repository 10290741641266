<template>
  <v-main>
    <v-container class="pa-8 mt-1 mb-2">
      <v-row class="flex-grow-1 mb-1">
        <div class="text-h4 transition-swing" v-text="'Neuer Artikel'"></div>
      </v-row>
      <v-row justify="center">
        <ProductDetails v-if="product.data !== undefined"/>
      </v-row>
      <v-row class="mt-8 mb-8"></v-row>
      <v-form v-model="valid">
        <v-row class="flex-grow-1 mb-3">
          <v-col cols="2">
            <div v-text="'SKU'"></div>
          </v-col>
          <v-col class="d-flex">
            <div v-text="newSku"></div>
          </v-col>
        </v-row>
        <v-row class="flex-grow-1 mb-3" :justify="'end'" :align="'center'">
          <v-col class="hidden-xs-only" cols="2">
            <div v-text="'Menge'"></div>
          </v-col>
          <v-col class="d-flex">
            <v-btn @click="stock += 1" color="primary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-text-field
                class="ml-2 mr-2"
                dense
                outlined
                inputmode="numeric"
                v-model="stock"
                hide-details
                :label="'Menge'"
            ></v-text-field>
            <v-btn @click="stock -= 1" :disabled="stock === 0" color="primary">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="lastPriceUsed && showLastPriceUsed" :justify="'start'">
          <v-btn dense text @click="setLastPriceUsed" color="primary">
            {{ 'Letzen Preis verwenden: ' + lastPriceUsed + '€' }}
          </v-btn>
        </v-row>
        <v-row class="flex-grow-1" :align="'start'">
          <v-col class="hidden-xs-only" cols="2">
            <div v-text="'UVP'"></div>
          </v-col>
          <v-col>
            <v-text-field
                v-model="decimalPrice"
                ref="price"
                dense
                outlined
                inputmode="numeric"
                :rules="priceRules"
                :label="'UVP'"
                :append-icon="'mdi-currency-eur'"
                :placeholder="'0,00'"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="flex-grow-1" :align="'start'">
          <v-col class="hidden-xs-only" cols="2">
            <div v-text="'SALE Preis (optional)'"></div>
          </v-col>
          <v-col>
            <v-text-field
                v-model="minPrice"
                ref="price"
                dense
                outlined
                inputmode="numeric"
                :rules="salePriceRules"
                :label="'SALE Preis (optional)'"
                :append-icon="'mdi-currency-eur'"
                :placeholder="'0,00'"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row :align="'end'" class="flex-grow-1 mt-5">
        <v-col>
          <v-btn class="pr-2" :height="70" block color="secondary" :disabled="!valid" @click="createProductAndHome">
            Angebot erstellen
          </v-btn>
        </v-col>
        <v-col>
          <v-btn class="pl-2" :height="70" block color="primary" :disabled="!valid" @click="createProductAndNew">Angebot
            erstellen + Neu
          </v-btn>
        </v-col>
      </v-row>
      <v-row :align="'end'" class="flex-grow-1 mt-5">

      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ProductDetails from "@/components/ProductDetails";
import priceUtil from "@/util/priceUtil";

export default {
  name: "AusbuchenExistingArticle",
  components: {
    ProductDetails
  },
  data() {
    return {
      stock: 1,
      formattedPrice: "",
      formattedMinPrice: "",
      valid: false,
      quantityRules: [
        v => !!v || "Required",
        v => !isNaN(parseInt(v)) || "Ungültige Menge"
      ],
      priceRules: [
        v => !!v || "Required",
        v => !isNaN(parseFloat(v)) || "Ungültiger Preis",
        v => parseFloat(v.replace(',', '.')) > 0 || "Ungültiger Preis",
        v => {
          if (v && this.formattedMinPrice) return (parseFloat(v.replace(',', '.')) >= parseFloat(this.formattedMinPrice.replace(',', '.'))) || "Sale-Preis muss kleiner als UVP sein"
          else return true
        }
      ],
      salePriceRules: [
        v => {
          if (v) return !isNaN(parseFloat(v)) || "Ungültiger Preis"
          else return true
        },
        v => {
          if (v) return parseFloat(v.replace(',', '.')) > 0 || "Ungültiger Preis"
          else return true
        },
        v => {
          if (v) return (parseFloat(v.replace(',', '.')) < parseFloat(this.formattedPrice.replace(',', '.'))) || "Sale-Preis muss kleiner als UVP sein"
          else return true
        }
      ],
      lastPriceUsed: this.$store.state.lastPriceUsed,
      showLastPriceUsed: true
    }
  },
  computed: {
    product: {
      get() {
        return this.$store.state.product
      }
    },
    decimalPrice: {
      get() {
        return this.formattedPrice
      },
      set(newPrice) {
        if (process.env.VUE_APP_DEBUG_MODE) console.log(newPrice)
        this.formattedPrice = priceUtil.mergePrice(this.formattedPrice, newPrice)
      },
    },
    minPrice: {
      get() {
        return this.formattedMinPrice
      },
      set(newPrice) {
        if (process.env.VUE_APP_DEBUG_MODE) console.log(newPrice)
        this.formattedMinPrice = priceUtil.mergePrice(this.formattedMinPrice, newPrice)
      }
    },
    newSku() {
      const date = new Date().toISOString()
      // 2021-03-14T14:29:37.299Z
      // 0    5  8
      const year = date.slice(2, 4)
      const month = date.slice(5, 7)
      const day = date.slice(8, 10)
      const hour = date.slice(11, 13)
      const minutes = date.slice(14, 16)

      const random1 = String(Math.floor(Math.random() * 1000)).padStart(3, '0');

      return month + ".LA" + year + random1 + day + hour + minutes
    }
  },
  methods: {
    createProduct() {
      const stock = this.stock
      const price = parseFloat(this.formattedPrice.replace(',', '.'))
      const minPrice = (this.formattedMinPrice) ? parseFloat(this.formattedMinPrice.replace(',', '.')) : null
      const newSku = this.newSku
      return this.$store.dispatch("createProduct", {stock, price, newSku, minPrice})
    },
    createProductAndHome() {
      const self = this
      this.createProduct()
          .then(function () {
            self.$store.commit("setEan", "");
            self.$store.commit("setLastPriceUsed", null);
            self.$router.push("/")
          })
    },
    createProductAndNew() {
      const self = this
      this.createProduct()
          .then(function () {
            self.$store.commit("setEan", "");
            self.$store.commit("setLastPriceUsed", self.decimalPrice);
            self.$router.push("/" + self.$store.state.actualFlow.flow)
          })
    },
    setLastPriceUsed() {
      this.formattedPrice = this.lastPriceUsed
      this.showLastPriceUsed = false
      this.$refs["price"].focus();
    }
  },
  mounted() {
    const self = this
    if (this.$store.state.ean === "" && this.$route.params['ean'] !== "undefined") {
      const ean = this.$route.params['ean']
      // needed if the site is reloaded
      this.$store.commit("setEan", ean);
      this.$store.commit("setActualFlow", {
        text: "Einbuchen",
        flow: "einbuchenalt",
        icon: "add.svg"
      });

      this.$store.dispatch("loadProduct", ean)
          .catch(function () {
            // on error go back to EAN Entry
            self.$router.push("/" + self.$store.state.actualFlow.flow)
          })
    }

  }
}
</script>

<style scoped>
input {
  text-align: right;
}
</style>