<template>
  <v-main>
    <v-container class="pa-8 mt-1 mb-2">
      <v-row class="flex-grow-1 mb-1">
        <div class="text-h4 transition-swing" v-text="'Neuer Artikel'"></div>
      </v-row>
      <v-row justify="center">
        <ProductDetails v-if="product.data !== undefined"/>
      </v-row>
      <v-row class="mt-8 mb-8"></v-row>
      <v-form v-model="valid">
        <v-row class="flex-grow-1" :align="'start'">
          <v-col class="hidden-xs-only" cols="2">
            <div v-text="'UVP (optional)'"></div>
          </v-col>
          <v-col>
            <v-text-field
                v-model="decimalPrice"
                ref="price"
                dense
                outlined
                inputmode="numeric"
                :rules="priceRules"
                :label="'neuer UVP (optional)'"
                :append-icon="'mdi-currency-eur'"
                :placeholder="'0,00'"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="flex-grow-1" :align="'start'">
          <v-col class="hidden-xs-only" cols="2">
            <div v-text="'SALE Preis (optional)'"></div>
          </v-col>
          <v-col>
            <v-text-field
                v-model="minPrice"
                ref="price"
                dense
                outlined
                inputmode="numeric"
                :rules="salePriceRules"
                :label="'neuer Sale Preis (optional)'"
                :append-icon="'mdi-currency-eur'"
                :placeholder="'0,00'"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row :align="'end'" class="flex-grow-1 mt-5">
        <v-col>
          <v-btn class="pr-2" :height="70" block color="secondary" :disabled="!valid" @click="changePrice">
            Preis ändern
          </v-btn>
        </v-col>
      </v-row>
      <v-row :align="'end'" class="flex-grow-1 mt-5">

      </v-row>
      <v-row justify="center">
        <div><strong>Hinweis</strong>: Preis wird nicht sofort geändert!</div>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ProductDetails from "@/components/ProductDetails";
import priceUtil from "@/util/priceUtil";

export default {
  name: "PriceChange",
  components: {
    ProductDetails
  },
  data() {
    return {
      stock: 1,
      formattedPrice: "",
      formattedMinPrice: "",
      valid: false,
      quantityRules: [
        v => !!v || "Required",
        v => !isNaN(parseInt(v)) || "Ungültige Menge"
      ],
      priceRules: [
        v => !v || !isNaN(parseFloat(v)) || "Ungültiger Preis1",
        v => !v || parseFloat(v.replace(',', '.')) > 0 || "Ungültiger Preis2",
        v => {
          if (v && this.formattedMinPrice) {
            let newPrice = parseFloat(v.replace(',', '.'));
            let minPrice = parseFloat(this.formattedMinPrice.replace(',', '.'));
            return (newPrice >= minPrice) || "Sale-Preis muss kleiner als UVP sein"
          } else return true
        }
      ],
      salePriceRules: [
        v => {
          if (v) return !isNaN(parseFloat(v)) || "Ungültiger Preis"
          else return true
        },
        v => {
          if (v) return parseFloat(v.replace(',', '.')) > 0 || "Ungültiger Preis"
          else return true
        },
        v => {
          if (v) {
            let newMinPrice = parseFloat(v.replace(',', '.'));
            let price = parseFloat(this.formattedPrice.replace(',', '.'));
            let oldPrice = this.$store.state.product.data.price;
            return ((!price || newMinPrice < price) && newMinPrice < oldPrice) || "Sale-Preis muss kleiner als UVP sein"
          } else return true
        }
      ],
      lastPriceUsed: this.$store.state.lastPriceUsed,
      showLastPriceUsed: true
    }
  },
  computed: {
    product: {
      get() {
        return this.$store.state.product
      }
    },
    decimalPrice: {
      get() {
        return this.formattedPrice
      },
      set(newPrice) {
        if (process.env.VUE_APP_DEBUG_MODE) console.log(newPrice)
        this.formattedPrice = priceUtil.mergePrice(this.formattedPrice, newPrice)
      },
    },
    minPrice: {
      get() {
        return this.formattedMinPrice
      },
      set(newPrice) {
        if (process.env.VUE_APP_DEBUG_MODE) console.log(newPrice)
        this.formattedMinPrice = priceUtil.mergePrice(this.formattedMinPrice, newPrice)
      }
    }
  },
  methods: {
    changePrice() {
      const self = this
      let request = {
        newPrice: parseFloat(this.formattedPrice.replace(",", ".")),
        newSalePrice: parseFloat(this.formattedMinPrice.replace(",", "."))
      }
      this.$store
          .dispatch("changePrice", request)
          .then(function (){
            self.$store.commit("setEan", "");
            self.$router.push("/");
          })
    }
  },
  mounted() {
    const self = this
    if (this.$store.state.ean === "" && this.$route.params['ean'] !== "undefined") {
      const ean = this.$route.params['ean']
      // needed if the site is reloaded
      this.$store.commit("setEan", ean);
      this.$store.commit("setActualFlow", {
        text: "Preis Ändern",
        flow: "preis",
        icon: "tag.png"
      });

      this.$store.dispatch("loadProduct", ean)
          .catch(function () {
            // on error go back to EAN Entry
            self.$router.push("/" + self.$store.state.actualFlow.flow)
          })
    }

  }
}
</script>

<style scoped>
input {
  text-align: right;
}
</style>