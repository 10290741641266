<script setup>

import {computed, onMounted, ref} from "vue";
import {useStore} from "@/store/store";
import ProductQuantityChanger from "@/components/ProductQuantityChanger.vue";
import validation from "@/util/validation";
import {useRouter} from "@/routes";
import util from "@/util/util";
import StorageLocationsTable from "@/components/StorageLocationsTable.vue";

const store = useStore()
const router = useRouter()

const eanValid = ref(false)
const locationValid = ref(false)

const ean = ref("")
// the ref is matched by the variable name
const eanField = ref(null)

const storageLocation = ref("")
// the ref is matched by the variable name
const storageField = ref(null)

// the ref is matched by the variable name

const eanRules = [
  v => !!v || "Required",
]

const storagePattern = /(\d+)\.(\d+)\.?(\d+)*/
const storageRules = [
  v => !!v || "Required",
  v => storagePattern.test(v) || "Ungültiger Format (Gang.Platz.Box)",
]

let storageLocations = computed(() => {
  return store.state.product.storageLocations
});

function doProductSearch() {
  if (eanValid.value) {
    storageField.value?.focus()

    store.dispatch('loadProductV2', ean.value)
  }
}

const originLocation = computed({
  get() {
    if (router.currentRoute.path === "/retoureEinlagern") {
      return store.state.returnStorageLocation
    }
    return store.state.tempStorageLocation
  }
})

function doMoveFromTempStorage() {
  if (!eanValid.value || !locationValid.value) return

  let regExpExecArray = validation.storagePattern.exec(storageLocation.value);
  const coordinates = {
    corridor: regExpExecArray[1],
    shelf: regExpExecArray[2],
    box: regExpExecArray[3]
  }
  const request = {
    ean: ean.value,
    quantityChange: store.state.actualProductQuantity,
    destinationLocation: coordinates,
    originLocation: originLocation.value
  }
  store.dispatch("changeStorageLocation", request).then(handleOk).catch(noop)
}

function handleOk() {
  let formattedOriginLocation = util.formatStorageLocation(originLocation.value);
  store.dispatch('showInfo', {text: "OK." + util.formatStorageMessage(store.state.product.ean, formattedOriginLocation, store.state.actualProductQuantity, storageLocation.value), time: 3000})
  reset()
}

function reset() {
  store.commit("setProduct", {})
  store.commit("setActualProductQuantity", 1)
  ean.value = ""
  storageLocation.value = ""
  eanField.value?.focus()
}

function noop() {
}

const title = computed({
  get() {
    if (router.currentRoute.path === "/retoureEinlagern") {
      return "Retoure Einlagern"
    }
    return "Einlagern"
  }
})

// hooks
onMounted(() => {
  reset()

  if (!store.state.tempStorageLocation) {
    store.dispatch("loadTempStorage")
  }
  if (!store.state.returnStorageLocation) {
    store.dispatch("loadReturnStorage")
  }

  eanField.value?.focus()
})

</script>

<template>
  <v-main class="mt-5 ml-5 mr-5">
    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="title"></div>
    </v-row>

    <v-row class="mt-5">
      <v-col sm="8" md="8" lg="8" xl="4">
        <v-form ref="eanEntry" v-model="eanValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="ean"
              ref="eanField"
              clearable
              dense
              outlined
              inputmode="numeric"
              :label="'EAN'"
              :rules="eanRules"
              @keyup.enter="doProductSearch"></v-text-field>
        </v-form>
      </v-col>

    </v-row>
    <v-row class="mt-5" v-if="eanValid">
      <v-col sm="8" md="8" lg="8" xl="4">
        <StorageLocationsTable :items="storageLocations"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" md="8" lg="8" xl="4">
        <ProductQuantityChanger/>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col sm="8" md="8" lg="8" xl="4">
        <v-form ref="storageEntry" v-model="locationValid" v-on:submit.prevent="noop">
          <v-text-field
              v-model="storageLocation"
              ref="storageField"
              clearable
              dense
              outlined
              :label="'Lagerplatz scannen'"
              :rules="storageRules"
              :disabled="!eanValid"
              @keyup.enter="doMoveFromTempStorage"></v-text-field>
        </v-form>
      </v-col>

    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" tile :disabled="!eanValid || !locationValid" @click="doMoveFromTempStorage">Einlagern
        </v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<style scoped>

</style>