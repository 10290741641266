<script setup>

import {useRouter} from "@/routes";

const router = useRouter()

</script>

<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row class="ml-5 mt-5 mr-5">
        <v-col :justify="'start'">
          <v-btn
              :height="100"
              x-large
              block
              color="primary"
              @click="router.push('/auslagern')">
            Auslagern
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ml-5 mt-5 mr-5">
        <v-col :justify="'start'">
          <v-btn
              :height="100"
              x-large
              block
              color="primary"
              @click="router.push('/einlagern')">
            Einlagern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style scoped>

</style>