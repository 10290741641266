<script setup>

import {computed} from "vue";
import {useStore} from "@/store/store";
import {useRouter} from "@/routes";

const store = useStore()
const router = useRouter()

let tableHeaders = [
  {
    text: "Gang",
    sortable: false,
    value: "corridor"
  },
  {
    text: "Platz",
    sortable: false,
    value: "shelf"
  },
  {
    text: "Box",
    sortable: false,
    value: "box"
  },
  {
    text: "Menge",
    sortable: false,
    value: "quantity"
  }
]

let storageLocations = computed(() => {
  return store.state.product.storageLocations

});

</script>

<template>
  <v-main class="ml-5 mt-5">

    <v-row class="flex-grow-1 mb-1 pl-3 mt-1">
      <div class="text-h4 transition-swing" v-text="'Abfrage'"></div>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="6" xl="6">
        <v-card class="pa-3 text-body-1">
          EAN: {{ router.currentRoute.params["ean"] }}
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5 mr-5">
      <v-col sm="12" md="12" lg="6" xl="6">
        <v-data-table
            disable-pagination
            :hide-default-footer="true"
            no-data-text="noch nicht im Lager"
            :headers="tableHeaders"
            :items="storageLocations"
            class="elevation-1"
            mobile-breakpoint="0"
        />
      </v-col>

    </v-row>
  </v-main>
</template>

<style scoped>

</style>