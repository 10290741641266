<template>
    <v-main>
        <v-container class="pa-8 mt-1 mb-2">
            <v-row class="flex-grow-1 mb-1">
                <v-col sm="12" md="12" lg="6" xl="6">
                    <v-data-table
                            disable-pagination
                            :hide-default-footer="true"
                            no-data-text="Daten noch nicht vorhanden"
                            :headers="headers"
                            :items="dashboardEntries"
                            class="elevation-1"
                            mobile-breakpoint="0"
                    >
                        <template v-slot="{}">
                            <tr>
                                <td >
                                    <b>GESAMT</b>
                                </td>
                                <td>
                                    <b>{{sumBooked}}</b>
                                </td>
                                <td>
                                    <b>{{sumPacked}}</b>
                                </td>
                                <td>
                                    <b>{{totalSum}}</b>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>

export default {
    name: "DispatchOverview",
    data() {
        return {
            backArrow: false,
            headers: [
                {
                    text: "",
                    sortable: false,
                    value: "shopName",
                    width: "25%"
                },
                {
                    text: "offen",
                    sortable: false,
                    value: "offen"
                },
                {
                    text: "bereits gepickt",
                    sortable: false,
                    value: "gepickt",
                    width: "25%"
                },
                {
                    text: "Summe",
                    sortable: false,
                    value: "summe",
                }
            ],
        }
    },
    computed: {
        dashboardEntries() {
            let result = []
            for (const i in this.$store.state.dispatchOverview.entries) {
                const entry = this.$store.state.dispatchOverview.entries[i];
                result.push({
                    shopName: entry.shopName,
                    offen: entry.countBookedOrders,
                    gepickt: entry.countPackedOrders,
                    summe: entry.countBookedOrders + entry.countPackedOrders
                })
            }
            return result
        },
        totalSum() {
            let totalSum = 0;
            for (const i in this.$store.state.dispatchOverview.entries) {
                const entry = this.$store.state.dispatchOverview.entries[i];
                totalSum += entry.countBookedOrders
                totalSum += entry.countPackedOrders
            }
            return totalSum
        },
        sumBooked() {
            let totalSum = 0;
            for (const i in this.$store.state.dispatchOverview.entries) {
                const entry = this.$store.state.dispatchOverview.entries[i];
                totalSum += entry.countBookedOrders
            }
            return totalSum
        },
        sumPacked() {
            let totalSum = 0;
            for (const i in this.$store.state.dispatchOverview.entries) {
                const entry = this.$store.state.dispatchOverview.entries[i];
                totalSum += entry.countPackedOrders
            }
            return totalSum
        }
    },
    methods: {},
    mounted() {
        this.$store.dispatch("loadDispatchOverview")
    }
}
</script>

<style scoped>

</style>