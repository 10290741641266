export default {
    mergePrice(actualPrice, newPrice) {
        const action = newPrice.length > actualPrice.length ? "adding" : "deleting"
        let result = newPrice
        if (!newPrice) {
            actualPrice = ""
        } else if (newPrice.includes(",")) {
            const elements = newPrice.split(",")

            let wholePart = elements[0];
            let decimalPart = elements[1];
            let shifted;
            if ((action === "adding" && decimalPart.length > 2)
                || (action === "deleting" && wholePart && decimalPart)) {
                shifted = this.shiftDigit(wholePart, decimalPart, action);
            } else {
                shifted = {wholePart, decimalPart}
            }
            if (action === "deleting" && !wholePart) {
                // no more digits in the whole part
                if (decimalPart) {
                    result = "," + shifted.decimalPart
                } else {
                    result = ""
                }
            } else {
                result = shifted.wholePart + "," + shifted.decimalPart
            }

        } else {
            if (action === "adding") {
                result = "," + newPrice
            }
        }

        return result
    },
    shiftDigit(wholePart, decimalPart, action) {
        if (action === "adding") {
            wholePart += decimalPart[0]
            decimalPart = decimalPart.substring(1)
        } else {
            decimalPart = wholePart.slice(-1) + decimalPart
            wholePart = wholePart.slice(0, -1)

        }
        return {wholePart, decimalPart}
    }
}