<template>
    <v-main>
        <v-container class="pa-8">
           <v-form ref="eanEntry" v-model="valid" v-on:submit.prevent="noop">
                <v-row>
                    <v-col class="flex-grow-1">
                        <v-text-field
                                v-model="ean"
                                ref="eanField"
                                clearable
                                dense
                                outlined
                                inputmode="numeric"
                                :label="'EAN'"
                                :rules="eanRules"
                                @keyup.enter="createLabelAndPrint"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn color="secondary" tile :disabled="!valid" @click="createLabelAndPrint">Label Drucken</v-btn>
                    </v-col>
                </v-row>
            </v-form>

        </v-container>
    </v-main>
</template>

<script setup>

import {ref} from "vue";
import {onMounted} from "vue";
import {useStore} from "@/store/store";

let store = useStore();

const ean = ref("")
const eanField = ref(null)
const valid = ref(false)
const eanRules = [
  v => !!v || "Required",
]

onMounted(() => {
  eanField.value?.focus();
})

function createLabelAndPrint() {
  store
      .dispatch("createPriceLabelAndPrint", {ean: padEAN(ean.value)})
      .then(function () {
        ean.value = ""
      })
}

function padEAN(ean) {
  let result = ean;
  if (ean.length < 8) {
    result = padToLength(ean, 8);
  } else if (ean.length > 8 && ean.length < 13) {
    result = padToLength(ean, 13);
  }
  return result;
}

function padToLength(ean, length) {
  let result = ean;
  while (result.length < length) {
    result = "0" + result;
  }
  return result;
}

</script>

<style scoped>

</style>