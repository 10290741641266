<template>
  <div id="scandit-barcode-picker"></div>

</template>

<script>
import * as ScanditSDK from "scandit-sdk";

export default {
  name: 'ScannerComponent',
  props: {
    onDetected: {
      type: Function,
      default(result) {
        if (process.env.VUE_APP_DEBUG_MODE) console.log('detected: ', result);
      },
    },
  },
  data: function() {
    return {
      barcodePicker: null
    };
  },
  mounted: function() {
    const self = this
    ScanditSDK.configure("ATIPEoSBEJUGNQYqvTGZuIoL+s0BQiHx1Xaz/KALR6tVeEU+RnAnV2Mv0BDOT9U+NCJZ7GFGpbRSaRD5FkAbTHFGcbDjbspqOEKRGa1iv+Z+ecyu+w3zrac2vi8YN3gWHQ2d4isGO8WeTYEpe33ayxjHTZPP25iCUArQK5DTORas2cX/StwIhDxXntMWPydBidbKnnGbh1yT9zz95s5di3/m5LuYi17JuZZTH0dP6xw6SH7JvzMGxbz63h4Yir+7lUVor74HFf9U24UJTtGJHDeCpCJ2s1vtwZiOZef7oDu8KgBMgZEwP9xfoBlHdDjgPF20vaMiWl2iNJ2KLs6dQESGsD8GDBLvrHVGx/Rz1mDFg1R5q8m6lST4K/hMJqc4hbvBmfmW0pgSC8Dyu2dDih7mXXqcByfR+kI+W+VGWmjuNj8HX7FPqGBQ7kf3WSrg/OViTbQNQJ48G1GFmScl0VoOUWr6yr+OVJDO2xH005S+kJcHKzjkt8ry0aMj7K7bJh5b/hewsEQ+AeM/BJmR424GpWU84xvwf0QLNapGpiv7BQlHt2OTI2cvsBOvqrs0BsrOuwl7f5/JMj2M9MaR21tVHF2FWg4NPp7lnkHdYq65rN6+VnuKfSuk43MUasB7jpEQjMgga1zs53xrJ4DG0Ck/j9YCTklyHJzKU5YTdcNCppv7zyB3WpbmQ22RlrbIMdhzilRhxce6KvpdZA3xQY0MkVCs/so4ubnaZmqPCiDb/rngLxB3qSoZaQ5WYoXK9saZJfj+4jUXUMC2Kb3fL8mUnYRXURVSt4FvrT3GX6kFO0gFnBA2hQ==", {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
    })
    .then(() => {
      return ScanditSDK.BarcodePicker.create(document.getElementById("scandit-barcode-picker"), {
        // enable some common symbologies
        scanSettings: new ScanditSDK.ScanSettings({
          enabledSymbologies: ["ean8", "ean13", "upca", "upce"],
          codeDuplicateFilter: 1000
        }),
      });
    })
    .then((barcodePicker) => {
      self.barcodePicker = barcodePicker
      // barcodePicker is ready here, show a message every time a barcode is scanned
      barcodePicker.on("scan", (scanResult) => {
        self.onDetected(scanResult.barcodes[0].data);
      });
    });

  },
  destroyed: function() {
    if (this.barcodePicker) {
      this.barcodePicker.destroy(true)
    }
  },
};
</script>

<style scoped>
#scandit-barcode-picker {
  max-width: 100%;
  max-height: 500px;
}
</style>
