import Vue from 'vue'
import axios from './plugins/vue-axios'
import App from './App.vue'
import router from './routes'
import vuetify from './plugins/vuetify';
import store from './store/store'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

Vue.use(VueObserveVisibility)

new Vue({
  router,
  vuetify,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
