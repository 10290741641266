<script setup>

import {useStore} from "@/store/store";
import {onMounted, ref} from "vue";

let store = useStore();
const quantityWarningDialog = store.state.quantityWarningDialog

const disabled = ref(true)

onMounted(() => {
  // enable button after three seconds
  setTimeout(() => {
    disabled.value = false
  }, 3000)
})

function okClicked() {
  store.state.quantityWarningDialog.callback()
  store.commit("hideQuantityWarningDialog")
}

function cancelClicked() {
  store.state.quantityWarningDialog.cancelCallback()
  store.commit("hideQuantityWarningDialog")
}



</script>

<template>
  <v-dialog
      v-if="quantityWarningDialog.visible"
      v-model="quantityWarningDialog.visible"
      color="primary"
      persistent
      max-width="600"
      height="300px"
      transition="fade-transition"
  >
    <!--    bigger -->
    <v-card class="pt-5" height="250px">
      <v-card-text justify="center">
        <div class="textContainer">
           {{ quantityWarningDialog.text }}
        </div>
      </v-card-text>

      <v-divider/>
      <v-card-actions>
        <v-btn
            :disabled="disabled"
            class="no-text-transform btn__p100"
            color="primary darken-1"
            text
            @click="okClicked"
        >
         <span style="white-space: normal;">
           OK
         </span>
        </v-btn>
        <v-btn
            class="no-text-transform btn__p100"
            color="red"
            text
            @click="cancelClicked"
        >
         <span style="white-space: normal;">
           Abbrechen
         </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.textContainer {
  display: flex;
  font-weight: bold;
  font-size: 150px;
  height: 160px;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}
</style>